import React, { useEffect } from 'react'
import { UiVbox, UiFeedbackSubscription } from '../../../ui'
import { Typography } from '@material-ui/core'
import SaveComprovanteButton from './SaveComprovanteButton'
import CardQRCode from '../../../components/CardQRCode'
import MyTicketsButtonStateful from '../shared/MyTicketsButtonStateful'
import styled from 'styled-components'
import useStore from '../../../stores/useStore'
import receiptLink from '../../../business/receipt/receiptLink'
import receiptFilename from '../../../business/receipt/receiptFilename'

const SaveComprovanteButtonStyled = styled(SaveComprovanteButton)`
  && {
    text-transform: none;
    border: 2px solid;
    :hover {
      border: 2px solid;
    }
  }
`

export function DonePaidScreen(props: any) {
  const { participantsInput, getParticipantOptionals, setIsSubscriptionComplete } = useStore()
  participantsInput.forEach((participant: any) => {
    getParticipantOptionals?.forEach((optional: any) => {
      if (participant.id === optional.participantId) participant.optionals = optional.optionals
    })
  })

  useEffect(() => {
    setIsSubscriptionComplete(true)
  }, [setIsSubscriptionComplete])

  return (
    <UiVbox spacing={0}>
      <Typography variant="h6">Pedido feito com sucesso!</Typography>
      <Typography variant="subtitle1">
        Os detalhes do seu pedido e comprovante de <br />
        inscrição foram enviados para o seu email.
      </Typography>
      {participantsInput &&
        participantsInput.map((participant: any) => {
          const url = receiptLink(participant.id)
          const filename = receiptFilename(participant.id)
          const optionals = participant.optionals || []
          return (
            <CardQRCode key={participant.id} participant={participant} optionals={optionals}>
              <SaveComprovanteButtonStyled variant="outlined" url={url} filename={filename} />
            </CardQRCode>
          )
        })}
      <MyTicketsButtonStateful />
      <UiFeedbackSubscription />
    </UiVbox>
  )
}
