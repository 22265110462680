import { Box } from '@material-ui/core'
import React, { useEffect } from 'react'
import { view } from 'react-easy-state'
import AppBarContainer from '../../shared/AppBarContainer'
import FooterBarContainer from '../../shared/FooterBarContainer'
import useStore from '../../stores/useStore'
import { UiButton } from '../../ui'
import { useLocation } from 'wouter'
import PixFormContainer from './PixFormContainer'
import PixDocumentLayout from './PixDocumentLayout'

function PixDocumentsPage({ params: { id } }: any) {
  const { setPaymentMethodId, initPix, pixInvalid } = useStore()
  id = Number(id)
  useEffect(() => {
    setPaymentMethodId(id)
    initPix()
  }, [setPaymentMethodId, initPix, id])

  const [, setLocation] = useLocation()

  return (
    <>
      <AppBarContainer />
      <Box p={1} mt={1}>
        <PixDocumentLayout>
          <PixFormContainer />
        </PixDocumentLayout>
      </Box>

      <FooterBarContainer>
        <UiButton
          disabled={pixInvalid}
          onClick={() => {
            setLocation(`/payment/pix/${id}`)
          }}>
          continuar
        </UiButton>
      </FooterBarContainer>
    </>
  )
}

export default view(PixDocumentsPage)
