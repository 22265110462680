import React from 'react'
import { Card } from '@material-ui/core'
import styled, { keyframes, css } from 'styled-components'

// interface IProps {
//   children: React.ReactChild,
//   showTag: boolean,
//   colorTag: string,
//   toggle: boolean,
//   expandInHeight: string,
//   expandOutHeight: string,
//   retractInHeight: string,
//   retractOutHeight: string
// }

const keyFrameExpand = props => {
  const { expandInHeight, expandOutHeight } = props

  return keyframes`
    0% {
      height: ${expandInHeight || '90px'};
    }
    100% {
      height: ${expandOutHeight || '300px'};
    }
  `
}

const keyFrameRetract = props => {
  const { retractInHeight, retractOutHeight } = props

  return keyframes`
    0% {
      height: ${retractInHeight || '300px'};
    }
    100% {
      height: ${retractOutHeight || '90px'};
    }
  `
}

const CardStyled = styled(Card)`
  && {
    display: flex;
    flex-direction: row;
    .MuiCardContent-root {
      padding-left: 0px;
    }
    margin-bottom: 10px;
    /* animation: ${props =>
      props.toggle
        ? css`
            ${keyFrameExpand({ ...props })} 0,5s ease-in-out 0s forwards;
          `
        : css`
            ${keyFrameRetract({ ...props })} 0,5s ease-in-out 0s forwards;
          `}; */
  }
`

const CardTag = styled.span`
  background: ${props => props.colorTag || 'green'};
  width: 10px;
`

const CardContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
`

const UiCard = ({ children, showTag = false, colorTag = '#1BA5A0', ...props }) => (
  <CardStyled {...props}>
    {showTag && <CardTag colorTag={colorTag} />}
    <CardContentStyled>{children}</CardContentStyled>
  </CardStyled>
)

export default UiCard
