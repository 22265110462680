import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { view } from 'react-easy-state'
import { UiButton } from '../../ui'
import { SadFace } from '../../icons'
import { useLocation } from 'wouter'
import useStore from '../../stores/useStore'
import { noty } from '../../components/SnackbarQueue'
import AppBarContainer from '../../shared/AppBarContainer'
import { getStatusTransaction } from '../../stores/services'
import { Sentry } from '../../plugins'
// import useStore from 'stores/useStore'

function PixResultPage() {
  const [, setLocation] = useLocation()
  const [loading, setLoading] = useState(false)
  const {
    resultMessage,
    setResultMessage,
    setPixPaymentCompleted,
    getUrl,
    regenerateCodes,
  } = useStore()

  const checkPixStatus = async () => {
    try {
      setLoading(true)
      const response = await getStatusTransaction(getUrl())
      if (response === null) {
        setLoading(false)
        noty.info('Aguardando pagamento.', { autoHideDuration: 4000 })
      } else if (response.status === false) {
        setResultMessage(response.message)
        setLoading(false)
        setLocation('/done/pixResult')
      } else {
        setPixPaymentCompleted(true)
        setLoading(false)
        setLocation('/done/paid')
      }
    } catch (error) {
      Sentry.captureException(error)
      setLoading(false)
      noty.error(error)
    }
  }

  return (
    <>
      <AppBarContainer />
      <Box textAlign="center" mt={4}>
        <SadFace />
      </Box>
      <Box m={1}>
        <Box m={2}>
          <Typography variant="h6" align="center">
            {resultMessage ? 'Ocorreu um problema' : 'Prazo para pagamento expirado'}
          </Typography>
        </Box>
        <Box m={4}>
          <Typography variant="body2" align="center">
            {resultMessage
              ? resultMessage
              : 'Infelizmente não recebemos o seu pagamento, favor reiniciar o processo de inscrição.'}
          </Typography>
        </Box>
        <Box m={4}>
          <UiButton
            color="secondary"
            onClick={() => {
              setLocation('/tickets')
              regenerateCodes()
            }}>
            Reiniciar Inscrição
          </UiButton>
        </Box>
        {resultMessage ? null : (
          <Box m={2}>
            <UiButton
              disabled={loading}
              variant="text"
              color="secondary"
              style={{ textTransform: 'none' }}
              onClick={checkPixStatus}>
              Já realizei o pagamento via Pix
            </UiButton>
          </Box>
        )}
      </Box>
    </>
  )
}

export default view(PixResultPage)
