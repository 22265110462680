export default function (userFields, eventFields) {
  const multiChoiceFields = eventFields.filter(
    el => el.kind === 'multi_choice' && el.options.length !== 0
  )

  if (multiChoiceFields.length === 0) {
    return eventFields
      .filter(f => f.required || f.kind === 'term')
      .map(f => f.id)
      .some(id => !userFields[id])
  }

  const eventFieldsWithoutMultiChoice = eventFields.filter(el => el.kind !== 'multi_choice')

  const hasInvalidField = eventFieldsWithoutMultiChoice
    .filter(f => f.required || f.kind === 'term')
    .map(f => f.id)
    .some(id => !userFields[id])

  const multiChoiceUserFields = multiChoiceFields
    .filter(el => el.required === true)
    .map(el => userFields[el.id])

  const areEmpty = multiChoiceUserFields.some(el => {
    const obj = Object.assign({}, el)
    if (obj === undefined) return true
    if (Object.keys(obj).length === 0) return true
    return false
  })

  if (hasInvalidField === false && areEmpty === false) return false
  else return true
}
