import { Sentry } from '../plugins'
import { postLog } from '../logflare/postLog'

export async function transactionErrorHandler(response) {
  if (response.status >= 400 && response.status <= 499) {
    throw await response.json()
  }
  if (response.status >= 500 && response.status <= 599) {
    const text = await response.text()
    const data = JSON.parse(text)
    await postLog(data, 'Erro 500 na validação do payload')
    Sentry.captureException(data)
    throw new Error('Erro interno do servidor. Tente mais tarde.')
  }
  throw await response.json()
}
