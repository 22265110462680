import emailRegex from '../../utils/emailRegex'
import isDocumentValid from '../../utils/isDocumentValid'

export default function(user) {
  if (user.name.length < 3) return true
  if (!user.document_type) return true
  if (!isDocumentValid(user.document_value, user.document_type)) return true
  if (!emailRegex.test(user.email)) return true
  return false
}
