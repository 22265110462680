import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Box } from '@material-ui/core'
import { UiHbox } from '../../../ui'
import useStore from '../../../stores/useStore'
import { useLocation } from 'wouter'
import Button from '../../../components/Button'
import DocumentTypeInput from '../../../inputs/DocumentTypeInput'
import FieldDocumentFormik from '../../../inputs/formik/FieldDocumentFormik'
import FieldFormik from '../../../inputs/formik/FieldFormik'
import MailCheckInputFormik from '../../../inputs/formik/MailCheckInputFormik'
import { noty } from 'components/SnackbarQueue'

// const fixture = {
//   name: 'Marcos Neves',
//   email: 'marcos.neves@gmail.com',
//   document_type: 'cpf',
//   document_value: '03169796941',
//   password: '123456',
//   password_confirmation: '123456',
//   accept: true
// }

export function SignupForm() {
  const { signUp } = useStore()
  const [, setLocation] = useLocation()

  const validationSchema = () =>
    yup.object().shape({
      name: yup.string().required('Nome é obrigatório!').min(5, 'Mínimo de 5 caracteres'),
      email: yup.string().trim().email('Email inválido!').required('Email é obrigatório!'),
      document_type: yup.string().required('Tipo de documento é obrigatório!'),
      document_value: yup.string().required('Documento é obrigatório!'),
      password: yup.string().required('Senha é obrigatório!').min(6, 'Mínimo de 6 caracteres'),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref('password')], 'Senha não confere')
        .required('Senha não confere'),
    })
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        document_type: '',
        document_value: '',
        password: '',
        password_confirmation: '',
      }}
      validationSchema={validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await signUp(values)
          setSubmitting(false)
          setLocation('/participants')
        } catch (error) {
          const authError = error?.errors ? Object.values(error.errors) : null
          setSubmitting(false)
          error?.errors && setErrors(error.errors)
          console.error('errors', authError || error)
          !authError && noty.error(error.message)
        }
      }}>
      {({
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        handleChange,
        handleBlur,
        values,
        setFieldValue,
      }) => {
        return (
          <>
            <Box mb={4}>
              <FieldFormik name="name" label="Nome" required />
              <MailCheckInputFormik name="email" label="Email" required />
              <UiHbox>
                <DocumentTypeInput
                  required
                  name="document_type"
                  onChange={value => setFieldValue('document_type', value)}
                  onBlur={handleBlur}
                  value={values.document_type}
                  error={touched.document_type && errors.document_type}
                />
                <FieldDocumentFormik
                  required
                  name="document_value"
                  documentType={values.document_type}
                />
              </UiHbox>
              <UiHbox>
                <FieldFormik name="password" label="Senha" type="password" required />
                <FieldFormik
                  name="password_confirmation"
                  label="Repetir Senha"
                  type="password"
                  required
                />
              </UiHbox>
            </Box>
            <Button
              type="submit"
              loading={isSubmitting}
              size="large"
              color="secondary"
              variant="contained"
              fullWidth
              onClick={handleSubmit}>
              CRIAR CONTA
            </Button>
          </>
        )
      }}
    </Formik>
  )
}
