import React from 'react'
import { Box } from '@material-ui/core'
import AppBarContainer from '../../shared/AppBarContainer'
import useStore from '../../stores/useStore'
import PaymentListContainer from './PaymentListContainer'
import { sessionStorage } from '../../safe-storage'

export function PaymentPage() {
  const { getParticipants } = useStore()
  sessionStorage.setItem('userDataParticipantsData', JSON.stringify(getParticipants()))
  return (
    <>
      <AppBarContainer />
      <Box p={1} mt={1}>
        <PaymentListContainer />
      </Box>
    </>
  )
}
