import { Box } from '@material-ui/core'
import { noty } from '../../components/SnackbarQueue'
import useBusy from '../../hooks/useBusy'
import React, { useEffect } from 'react'
import { view } from 'react-easy-state'
import AppBarContainer from '../../shared/AppBarContainer'
import FooterBarContainer from '../../shared/FooterBarContainer'
import useStore from '../../stores/useStore'
import { UiButton } from '../../ui'
import UiBusyWrapper from '../../ui/UiBusyWrapper'
import { useLocation } from 'wouter'
import CardFormContainer from './CardFormContainer'
import CardLayout from './CardLayout'
import { Sentry } from '../../plugins'
import { Pixel } from '../../business'

function PaymentCardPage({ params: { id } }) {
  const { setPaymentMethodId, creditInvalid, enrollByCard, setUrl, event } = useStore()
  const [busy, busyPay] = useBusy(pay, 'PaymentCardPage')

  useEffect(() => {
    setPaymentMethodId(id)
  }, [setPaymentMethodId, id])

  const [, setLocation] = useLocation()

  async function pay() {
    event.integrations.pixel_add_payment_info && Pixel.AddPaymentInfo(event.integrations.pixel_id)
    try {
      const url = await enrollByCard()
      setUrl(url)
      setLocation('/done/ProcessingPage')
    } catch (e) {
      console.log(e)
      if (e.message) {
        if (e.message === 'Erro Desconhecido!')
          noty.error('Falha no pagamento. Infelizmente não foi possível realizar o pagamento.')
        else noty.error(e.message)
      } else noty.error(e.message)
      Sentry.captureException(e)
    }
  }

  return (
    <>
      <AppBarContainer />

      <Box p={1} mt={1}>
        <CardLayout>
          <CardFormContainer />
        </CardLayout>
      </Box>

      <FooterBarContainer>
        <UiBusyWrapper busy={busy} uid="PaymentCardPage">
          <UiButton disabled={creditInvalid} onClick={busyPay}>
            pagar e finalizar
          </UiButton>
        </UiBusyWrapper>
      </FooterBarContainer>
    </>
  )
}

export default view(PaymentCardPage)
