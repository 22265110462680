import React, { useState } from 'react'
import { saveUrl } from '../../../utils'
import { BusyButton } from '../../../components/Busy'
import { GA } from 'business'
import useStore from 'stores/useStore'

function download(url, filename, event) {
  GA.Receipt(event.integrations.google_analytics)
  return saveUrl(url, filename)
}

function useLoading(fn) {
  const [isLoading, setLoading] = useState(false)
  function wrapper(...args) {
    setLoading(true)
    let promise = fn(...args)
    if (promise && promise.then && promise.catch) {
      promise.then(() => setLoading(false)).catch(() => setLoading(false))
    } else {
      setLoading(false)
    }
    return promise
  }
  return [isLoading, wrapper]
}

export default ({ url, filename, ...props }) => {
  let [isLoading, promisedDownload] = useLoading(download)
  const { event } = useStore()
  return (
    <BusyButton
      title={filename + ' - ' + url}
      busy={isLoading}
      disabled={isLoading}
      onClick={() => promisedDownload(url, filename, event)}
      {...props}>
      Salvar comprovante
    </BusyButton>
  )
}
