export async function postLog(payload, message) {
  const url =
    'https://api.logflare.app/logs/json?api_key=y-ap7DUq8pyK&source=2561f024-3cd9-4d15-98f8-bbce1d896826'
  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
  }

  const body = {
    event_message: message,
    metadata: payload,
  }
  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  })
  const data = await response.json()
  console.log(data)
}
