import React, { useState } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import UiTextField from '../../ui/UiTextField'

import { Visa, MasterCard, Discover, Diners, Elo, Amex, Generic, JCB, Hiper, Aura } from './icons'
import { getCardTypeByValue } from './getCardType'

const CardIcon = flag => {
  switch (flag && flag.type) {
    case 'visa':
      return <Visa />
    case 'mastercard':
      return <MasterCard />
    case 'discover':
      return <Discover />
    case 'dinersclub':
      return <Diners />
    case 'elo':
      return <Elo />
    case 'amex':
      return <Amex />
    case 'jcb':
      return <JCB />
    case 'hipercard':
      return <Hiper />
    case 'aura':
      return <Aura />
    default:
      return <Generic />
  }
}

const PaymentInput = ({ onChange, value, id, label, ...props }) => {
  const [cardIcon, setCardIcon] = useState('')
  return (
    <>
      <UiTextField
        id={id}
        label={label || 'Número do cartão'}
        onChange={value => {
          setCardIcon(getCardTypeByValue(value))
          onChange(value)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon>{CardIcon(cardIcon)}</Icon>
            </InputAdornment>
          ),
          inputMode: 'numeric',
        }}
        value={value}
        {...props}
      />
    </>
  )
}

export default PaymentInput
