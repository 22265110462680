import React from 'react'
import { UiButton } from '../../ui'
import { useLocation } from 'wouter'
import useStore from '../../stores/useStore'
import { view } from 'react-easy-state'
import { noty } from '../../components/SnackbarQueue'
import { useCustomFieldsContext } from './CustomFieldsModule/CustomFieldsContext'
import { Pixel, GA } from '../../business'

function NextButtonContainer() {
  const { hasParticipantsInvalid, hasOptional, listInvalidParticipantsFields, event } = useStore()
  const [, setLocation] = useLocation()
  const customFieldsContext = useCustomFieldsContext()

  function nextPage() {
    if (hasParticipantsInvalid) {
      const fieldsContext = customFieldsContext
      fieldsContext.forEach((field: any, index: any) => {
        listInvalidParticipantsFields[0].forEach((fieldId: any) => {
          if (field.id === fieldId)
            customFieldsContext[index] = { ...customFieldsContext[index], error: true }
        })
      })
      noty.error('Há campos não preenchidos ou preenchidos incorretamente.')
    } else {
      event.integrations.pixel_lead && Pixel.Lead(event.integrations.pixel_id)
      GA.Participants(event.integrations.google_analytics)
      setLocation(hasOptional ? '/optionals' : '/payment')
    }
  }

  return <UiButton onClick={nextPage}>continuar</UiButton>
}

export default view(NextButtonContainer)
