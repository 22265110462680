import React from 'react'
import {
  ListItem,
  ListItemIcon,
  Paper,
  ListItemText,
  Box,
  Typography,
  TypographyProps,
} from '@material-ui/core'
import { Link } from 'wouter'
import { Real } from '../../utils'
import { paymentTypeMap } from './paymentTypeMap'
import { KeyboardArrowRightIcon } from '../../icons'
import { InstalmentsDetails } from './InstalmentsDetails'
import instalmentOptions from '../../business/instalmentOptions'
import useStore from 'stores/useStore'

type ListItemProps = {
  payment_type: string
  number_of_instalments: number
  price: number
  id: number | string
}

export function PaymentListItem({
  payment_type = 'boleto',
  number_of_instalments = 1,
  price = 0,
  id = 1,
}: ListItemProps) {
  const { fee, leaderParticipant } = useStore()
  const paymentType = paymentTypeMap[payment_type]
  const PaymentTypeIcon = paymentType.icon
  const label = paymentType.label
  const amountLabel = buildAmountLabel(price, number_of_instalments)
  const href =
    payment_type === 'pix' && !['cpf', 'cnpj'].includes(leaderParticipant.document_type)
      ? '/payment/pixDocuments/' + id
      : paymentType.url + id

  return (
    <Box mb={1}>
      <Paper style={{ position: 'relative' }}>
        <div style={link}>
          <KeyboardArrowRightIcon color="secondary" fontSize="large" />
        </div>
        <Link href={href} style={link}></Link>
        <ListItem style={{ pointerEvents: 'none' }}>
          <ListItemIcon style={{ justifyContent: 'center' }}>
            <PaymentTypeIcon color="secondary" fontSize="large" />
          </ListItemIcon>
          <ListItemText
            secondaryTypographyProps={secondary}
            primary={label}
            style={{ paddingLeft: '10px' }}
            secondary={
              <>
                <Typography variant="body2" gutterBottom>
                  {amountLabel}
                </Typography>
                {number_of_instalments > 1 && (
                  <InstalmentsDetails
                    instalmentOptions={instalmentOptions(
                      number_of_instalments,
                      price,
                      payment_type === 'cartao parcelado acrescimo' ? true : false,
                      fee
                    )}
                  />
                )}
              </>
            }
          />
        </ListItem>
      </Paper>
    </Box>
  )
}
const secondary: TypographyProps & { component: React.ElementType } = {
  component: 'div',
}

const link: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}

function buildAmountLabel(amount_in_cents: number, number_of_instalments: number) {
  if (amount_in_cents === 0) return 'GRATUITO'
  const formatted_amount = Real({ value: amount_in_cents })
  if (number_of_instalments === 1) return `${formatted_amount} à vista`
  return `${formatted_amount} em até ${number_of_instalments}x`
}
