import React from 'react'
import { UiEmailInput, UiTextField, UiHbox } from '../../ui'
import DocumentTypeInput from '../../inputs/DocumentTypeInput'
import DocumentInput from '../../inputs/DocumentInput'
import { view } from 'react-easy-state'

function ParticipantForm({ input = console.warn }) {
  const { value: documentType } = input('document_type') || { value: 'cpf' }

  return (
    <form>
      <UiTextField label="Nome completo" required {...input('name')} />
      <UiHbox>
        <DocumentTypeInput required {...input('document_type')} />
        <DocumentInput required documentType={documentType} {...input('document_value')} />
      </UiHbox>
      <UiEmailInput required {...input('email')} />
    </form>
  )
}

export default view(ParticipantForm)
