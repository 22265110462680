import React from 'react'
import MaskedInput from 'react-text-mask'
import { UiTextField } from '../ui'

export default function UiMaskedInput({ mask = false, guide = false, ...props }) {
  return (
    <UiTextField
      {...props}
      InputProps={{
        inputProps: { mask, guide },
        inputComponent: UiMaskedInputInternal,
      }}
    />
  )
}

function UiMaskedInputInternal({ inputRef, mask, guide, defaultValue, value, ...props }) {
  return (
    <MaskedInput
      defaultValue={value || defaultValue} // defaultValue tem precedência sobre o value,
      // por isso é utilizado o value primeiro e se não existir, o defaultValue
      // BUG: https://github.com/text-mask/text-mask/issues/806
      value={value}
      mask={mask}
      guide={guide}
      {...props}
      ref={inputRef && (ref => inputRef(ref ? ref.inputElement : null))}
    />
  )
}

// export default ({ value = '', ...props }) => (
//   <MaskedInput
//     mask={false}
//     guide={false}
//     value={value}
//     {...props}
//     render={(ref, inputProps) => (
//       <UiTextField
//         InputLabelProps={{ shrink: true }} // quick fix label position bug
//         inputRef={ref}
//         {...inputProps}
//       />
//     )}
//   />
// )
