import React, { useState } from 'react'
import { Typography, Collapse, CardContent } from '@material-ui/core'
import { RealOrGratuito } from '../../utils'
import StepInput from '../../inputs/StepInput'
import { CategoryItemLayout } from './CategoryItemLayout'
import styled from 'styled-components'
import { UiLink } from '../../ui'

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const ColOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ColTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
type TypographyProps = {
  size?: string
  weight?: string
}

const TypographyStyled = styled(Typography)`
  && {
    font-size: ${(props: TypographyProps) => (props.size ? props.size : '13px')};
    font-weight: ${(props: TypographyProps) => (props.weight ? props.weight : '400')};
  }
`

const UiLinkStyled = styled(UiLink)`
  && {
    margin-top: 6px;
  }
`

const CardContentStyled = styled(CardContent)`
  && {
    margin: 0px;
    &&.MuiCardContent-root:last-child {
      padding-bottom: 8px;
    }
  }
`

type CategoryItemProps = {
  qty: number
  name?: string
  price: number
  limit: number
  onChange: any
  visible: boolean
  list_of_coupons: any
  soldOut: any
  paymentMethods: {
    id: number
    payment_type: string
    amount_in_cents: number
    number_of_instalments: number
    begin_at: string
    end_at: string
    price: number
  }[]
}

export function CategoryItem({
  qty = 0,
  name = 'Entrada',
  price = 0,
  limit = 1,
  onChange,
  visible,
  soldOut = false,
  list_of_coupons: coupons,
  paymentMethods,
}: CategoryItemProps) {
  const [toggle, setToggle] = useState(false)

  // const buildAmountLabel = (amount_in_cents, number_of_instalments) => {
  //   if (amount_in_cents === 0) return 'GRATUITO'
  //   const formatted_amount = Real({ value: amount_in_cents })
  //   if (number_of_instalments === 1) return ` à vista ${formatted_amount}`
  //   return ` em até ${number_of_instalments}x - ${formatted_amount}`
  // }

  const handleToggle = () => setToggle(!!!toggle)

  const hasFrom = paymentMethods.every(
    el => paymentMethods[0].amount_in_cents === el.amount_in_cents
  )

  return (
    <CategoryItemLayout showTag={!visible}>
      <Content>
        <ColOne>
          <Typography variant="h6">{name}</Typography>
          <Typography>
            {price === 0 ? '' : !hasFrom && 'A partir de '}
            <RealOrGratuito value={price} />
          </Typography>
        </ColOne>
        <ColTwo>
          {!soldOut ? <StepInput value={qty} onChange={onChange} max={limit} /> : 'Esgotado'}
        </ColTwo>
      </Content>
      {coupons.length >= 1 && visible && (
        <Collapse in={toggle} timeout="auto" unmountOnExit>
          <CardContentStyled>
            {coupons.length >= 1 && visible && (
              <>
                <TypographyStyled>
                  Aplique um cupom de desconto para desbloquear esta categoria
                </TypographyStyled>
                {/* <br /> */}
              </>
            )}
            {/* <TypographyStyled size="16px" weight="500">
            Formas de pagamento
          </TypographyStyled>
          {paymentMethods.map(payment => {
            return (
              <TypographyStyled>
                {paymentTypeMap[payment.payment_type].label}
                {buildAmountLabel(price, payment.number_of_instalments)}
                <br />
              </TypographyStyled>
            )
          })} */}
          </CardContentStyled>
        </Collapse>
      )}
      {coupons.length >= 1 && visible && (
        <UiLinkStyled href="#" onClick={handleToggle}>
          {toggle ? 'Ocultar detalhes' : 'Ver detalhes'}
        </UiLinkStyled>
      )}
    </CategoryItemLayout>
  )
}
