import { Box } from '@material-ui/core'
import { parse } from 'query-string'
import React, { useEffect } from 'react'
import { view } from 'react-easy-state'
import AppBarContainer from '../../shared/AppBarContainer'
import FooterBarContainer from '../../shared/FooterBarContainer'
import useStore from '../../stores/useStore'
import { UiButton, UiText, UiLink } from '../../ui'
import redirect from '../../utils/redirect'
import { useLocation } from 'wouter'
import CategoryListContainer from './CategoryListContainer'
import CouponContainer from './CouponContainer'
import { GroupDiscountContainer } from './GroupDiscountContainer'
import { GA, Pixel } from 'business'

function TicketsPage() {
  const {
    hasCoupon,
    cartDisabled,
    setPixPayTimeExpired,
    setPixPaymentCompleted,
    setResultMessage,
    event,
  } = useStore()
  const [, setLocation] = useLocation()

  useEffect(() => {
    const sendPixel = async () => {
      Pixel.InitiateCheckout(event.integrations.pixel_id)
    }
    event.integrations?.pixel_initiate_checkout && sendPixel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.integrations?.pixel_id, event.integrations?.pixel_initiate_checkout])

  setPixPayTimeExpired(false)
  setPixPaymentCompleted(false)
  setResultMessage('')
  const params = parse(document.location.search)
  const shouldDisplayHeader = !params.show_header || params.show_header === 'true'
  // const seo = useContext(seoContext)
  // seo.pageview(location)

  function continuar() {
    GA.Subscription(event.integrations.google_analytics)
    setLocation(redirect('participants'))
  }

  const SubscriptionsUnavailable = (
    <Box textAlign="center">
      <UiText>Inscrições indisponíveis</UiText>
      <UiText>
        Saiba mais acessando{' '}
        <UiLink
          href={`https://www.e-inscricao.com/${event.organization?.slug}/${event.id}`}
          target="_blank">
          a página do evento.
        </UiLink>
      </UiText>
    </Box>
  )

  return (
    <>
      {shouldDisplayHeader && <AppBarContainer />}
      <GroupDiscountContainer />
      <Box p={1} mt={1}>
        {hasCoupon && <CouponContainer />}
        {event.free === false && event.payment_methods.length === 0 ? (
          SubscriptionsUnavailable
        ) : (
          <CategoryListContainer />
        )}
      </Box>
      <FooterBarContainer>
        <UiButton disabled={cartDisabled} onClick={continuar}>
          continuar
        </UiButton>
      </FooterBarContainer>
    </>
  )
}

export default view(TicketsPage)
