import * as Sentry from '@sentry/browser'
import { PROD, TEST } from '../env'

if (PROD || TEST) {
  // Sentry.init({dsn: "https://c597f517cd4e4ce48ff7560bd7d3bb00@sentry.io/1544847"})

  //glitchtip
  Sentry.init({ dsn: 'https://5d6e437e99f7431c9999d9e8d382cc4b@app.glitchtip.com/2226' })

  //Sentry
  // Sentry.init({
  //   dsn: 'https://b951a75aef114e02830e27559d510e05@o84559.ingest.sentry.io/5963112',
  //   tracesSampleRate: 0.5,
  // })
  // Sentry.setTag('environment', NODE_ENV)
}

export default Sentry
