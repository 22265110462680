import React, { useCallback, useEffect } from 'react'
import { Route, useLocation } from 'wouter'
import { AuthPage } from './AuthPage'
import DebitPage from './DebitPage'
import { DonePage as DoneBoletoPage } from './done/DoneBoletoPage'
import { DonePage as DonePaidPage } from './done/DonePaidPage'
import ParticipantsPage from './ParticipantsPage'
import PaymentBoletoPage from './PaymentBoletoPage'
import PaymentCreditPage from './PaymentCreditPage'
import PaymentDebitPage from './PaymentDebitPage'
import PaymentPixPage from './PaymentPixPage'
import { PaymentPage } from './PaymentPage'
import TicketsPage from './TicketsPage'
import OptionalPage from './OptionalPage'
import HomePage from './HomePage'
import PixQrCodePage from './PixQrCodePage'
import PixResultPage from './PixResultPage'
import ErrorPage from './ErrorPage'
import ProcessingPage from './ProcessingPage'
import PixDocumentsPage from './PixDocumentsPage'
import useStore from 'stores/useStore'
// import SEO from '../components/seo/seo'
// import { SEOProvider } from '../components/seo/seoContext'

// const gaConfig = [
//   {
//     trackingId: 'UA-000000-01',
//     gaOptions: { name: 'tracker1', userId: '123' },
//   },
//   {
//     trackingId: 'UA-000000-02',
//     gaOptions: { name: 'tracker2', userId: '123' },
//   },
// ]

// const seo = SEO('540617613375726', gaConfig)

export default function Pages() {
  const {
    getIsSubscriptionComplete,
    setIsSubscriptionComplete,
    regenerateCodes,
    resetCart,
  } = useStore()
  const [, setLocation] = useLocation()

  const handleLoad = useCallback(() => {
    if (getIsSubscriptionComplete()) {
      setIsSubscriptionComplete(false)
      regenerateCodes()
      resetCart()
      setLocation('/tickets')
    }
  }, [
    getIsSubscriptionComplete,
    setIsSubscriptionComplete,
    resetCart,
    regenerateCodes,
    setLocation,
  ])

  useEffect(() => {
    window.addEventListener('popstate', handleLoad)
    return () => {
      window.removeEventListener('popstate', handleLoad)
    }
  }, [handleLoad])

  return (
    <>
      {/* <SEOProvider value={seo}> */}
      <Route path="/" component={HomePage} />
      <Route path="/tickets" component={TicketsPage} />
      <Route path="/optionals" component={OptionalPage} />
      <Route path="/auth/:slug*" component={AuthPage} />
      <Route path="/participants" component={ParticipantsPage} />
      <Route path="/payment/boleto/:id" component={PaymentBoletoPage} />
      <Route path="/payment/credit/:id" component={PaymentCreditPage} />
      <Route path="/payment/debit/:id" component={PaymentDebitPage} />
      <Route path="/payment/pix/:id" component={PaymentPixPage} />
      <Route path="/payment/pixDocuments/:id" component={PixDocumentsPage} />
      <Route path="/debit" component={DebitPage} />
      <Route path="/payment" component={PaymentPage} />
      <Route path="/done/boleto" component={DoneBoletoPage} />
      <Route path="/done/pix" component={PixQrCodePage} />
      <Route path="/done/paid" component={DonePaidPage} />
      <Route path="/done/pixResult" component={PixResultPage} />
      <Route path="/done/ErrorPage" component={ErrorPage} />
      <Route path="/done/ProcessingPage" component={ProcessingPage} />
      {/* </SEOProvider> */}
    </>
  )
}
