import { Box } from '@material-ui/core'
import { noty } from '../../components/SnackbarQueue'
// import useBusy from 'hooks/useBusy'
import React, { useState, useEffect } from 'react'
import { view } from 'react-easy-state'
import AppBarContainer from '../../shared/AppBarContainer'
import FooterBarContainer from '../../shared/FooterBarContainer'
import useStore from '../../stores/useStore'
import { UiButton } from '../../ui'
import UiBusyWrapper from '../../ui/UiBusyWrapper'
import { useLocation } from 'wouter'
import BoletoContainer from './BoletoContainer'
import BoletoLayout from './BoletoLayout'
import { Sentry } from '../../plugins'
import { Pixel } from '../../business'

function PaymentBoletoPage({ params: { id } }) {
  const [loading, setLoading] = useState(false)
  id = Number(id)
  const {
    setPaymentMethodId,
    boletoInvalid,
    initBoleto,
    enrollByBoleto,
    setUrl,
    event,
  } = useStore()

  useEffect(() => {
    setPaymentMethodId(id)
    initBoleto()
  }, [setPaymentMethodId, initBoleto, id])

  const [, setLocation] = useLocation()

  const pay = async () => {
    try {
      event.integrations.pixel_add_payment_info && Pixel.AddPaymentInfo(event.integrations.pixel_id)
      setLoading(true)
      const url = await enrollByBoleto()
      if (url) setUrl(url.url_status)
      setLoading(false)
      setLocation('/done/ProcessingPage')
    } catch (error) {
      Sentry.captureException(error)
      setLoading(false)
      noty.error(error.message || error.json?.message?.substr(0, 100))
    }
  }

  return (
    <>
      <AppBarContainer />

      <Box p={1} mt={1}>
        <BoletoLayout>
          <BoletoContainer />
        </BoletoLayout>
      </Box>

      <FooterBarContainer>
        <UiBusyWrapper busy={loading} uid="PaymentBoletoPage">
          <UiButton disabled={boletoInvalid} onClick={pay}>
            Gerar boleto e finalizar
          </UiButton>
        </UiBusyWrapper>
      </FooterBarContainer>
    </>
  )
}

export default view(PaymentBoletoPage)
