import { API_URL } from '../env'
import { transactionErrorHandler } from './transactionErrorHandler'

export async function validateEnrollment(body) {
  // const http500 = 'http://httpstat.us/500'
  const url = `${API_URL}/api/v2/enroll/validate`

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  })

  try {
    if (!response.ok) {
      await transactionErrorHandler(response)
    }
    const data = await response.json()
    return data
  } catch (e) {
    throw e
  }
}
