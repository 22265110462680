import React from 'react'
import { UiCheckbox, UiLabel } from '../../../../ui'

export default function MultiChoiceCheckKind({
  options,
  label,
  required,
  value = [],
  onChange,
  ...props
}) {
  const optionsArray = options.map(o => [o.id, o.label, disabled(o)])

  function onChangeCheck(checked, key) {
    let values = []
    if (checked) {
      values = [...value, key]
    } else {
      values = value.filter(i => i !== key)
    }
    onChange(values)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <UiLabel required={required} fontSize="14px" marginTop="10px">
        {label}
      </UiLabel>
      {optionsArray.map(([key, label, disabled]) => (
        <UiCheckbox
          name={'_' + key}
          required={required}
          label={label}
          key={key}
          value={key}
          disabled={disabled}
          onChange={checked => onChangeCheck(checked, key)}
          checked={isChecked(key, value)}
          {...props}
        />
      ))}
    </div>
  )
}

function disabled(o) {
  return o.limit !== null && o.current_in_use >= o.limit
}

function isChecked(key, array) {
  return array.includes(key)
}
