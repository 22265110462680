import { MS_BASE_URL } from '../env'
import wretch from 'wretch'
//import PAGARME_ERRORS from '../business/pagarme/errors.json'
// const API_URL = 'https://us-central1-e-inscricao.cloudfunctions.net'

const convertToJson = w => w.json()

const Debit = wretch('https://us-central1-e-inscricao.cloudfunctions.net')
  .catcher('SyntaxError', e => {
    console.error(e)
    throw e
  }) // bad json
  .catcher(404, e => {
    console.error(e)
    throw Error('Serviço não encontrado.')
  })
  .catcher(400, e => {
    console.error(e)
    throw Error('Dados inválidos.')
  })
  .catcher(500, e => {
    console.error(e)
    throw Error('Serviço indisponível.')
  })
  .errorType('json')
  .accept('application/json')
  .content('application/json')
  .resolve(convertToJson)

const R = wretch(MS_BASE_URL)
  .catcher('SyntaxError', e => {
    console.error(e)
    throw e
  }) // bad json
  .catcher(403, e => {
    console.error(e)
    throw Error(
      'Serviço bloqueado pela rede. Contate o administrador da rede local utilizada para realizar esta inscrição.'
    )
  })
  .catcher(404, e => {
    console.error(e)
    throw Error('Serviço não encontrado. Tente novamente mais tarde.')
  })
  .catcher(406, e => {
    console.error(e.json)
    throw Error(e.json.message)
  })
  .catcher(500, e => {
    console.error(e.json)
    throw Error('Algo deu errado. Contate nosso suporte.')
  })
  .catcher(503, e => {
    console.error(e.json)
    throw Error('Serviço indisponível no momento. Tente mais tarde.')
  })
  .errorType('json')
  .accept('application/json')
  .content('application/json')
  .resolve(convertToJson)

const Services = {
  pagarmeCard(data) {
    return R.url('/transactionCard').post(data)
  },

  pagarmeBoleto(data) {
    // TODO tratar "status": "refused" ou diferente de wainting_payment
    // TODO trazer os payables
    // return R.url('/pagarmeBoleto').post(data)
    return R.url('/transactionBoleto').post(data)
  },

  pagarmePix(data) {
    return R.url('/create-pix').post(data)
  },

  free(data) {
    return R.url('/freeTransaction').post(data)
  },

  cieloDebit(data) {
    return Debit.url('/cieloDebit').post(data)
  },
}

export async function getStatusTransaction(url) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: headers,
  })
  if (response.status >= 200 && response.status <= 299) {
    const data = await response.json()
    return data
  }
  if (response.status >= 400 && response.status <= 499) {
    return null
  }
  if (response.status >= 500 && response.status <= 599) {
    const message = `Ocorreu algum problema. ${response.status}`
    return { status: false, message: message }
  }
}

export async function resendPostback(id) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }

  const url = `${MS_BASE_URL}/resendPostback`
  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ id: `s2p${id}` }),
  })

  if (response.status >= 200 && response.status <= 299) {
    const data = await response.json()
    return data
  }
  if (response.status >= 400 && response.status <= 499) {
    return null
  }
  if (response.status >= 500 && response.status <= 599) {
    const message = `Ocorreu algum problema. ${response.status}`
    return { status: false, message: message }
  }
}

export default Services
