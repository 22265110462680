export const GA = {
  Subscription(gaId) {
    InitGA(gaId, 'inscricoes')
  },
  Login(gaId) {
    InitGA(gaId, 'login')
  },
  Create(gaId) {
    InitGA(gaId, 'create')
  },
  Participants(gaId) {
    InitGA(gaId, 'participantes')
  },
  Receipt(gaId) {
    InitGA(gaId, 'comprovante')
  },
  UserId(gaId, userId) {
    InitGA(gaId, 'userId', String(userId))
  },
}

function InitGA(gaId, event, data = null) {
  /* eslint-disable */
  if (gaId) {
    if (typeof ga === 'undefined') {
      ;(function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r
        ;(i[r] =
          i[r] ||
          function () {
            ;(i[r].q = i[r].q || []).push(arguments)
          }),
          (i[r].l = 1 * new Date())
        ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
        a.async = 1
        a.src = g
        m.parentNode.insertBefore(a, m)
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')
      ga('create', gaId, 'auto', { cookieFlags: 'SameSite=None; Secure' })
      if (data) {
        ga('set', 'userId', data)
      } else {
        ga('send', 'event', 'inscricao', event)
      }
    } else {
      if (data) {
        ga('set', 'userId', data)
      } else {
        ga('send', 'event', 'inscricao', event)
      }
    }
  }
}
