import React, { useEffect } from 'react'
import { UiTextField, UiHbox, UiCheckbox } from '../../ui'
import ExpirationInput from '../../inputs/ExpirationInput'
import CvvInput from '../../inputs/CvvInput'
import InstalmentInput from '../../inputs/InstalmentInput'
import { view } from 'react-easy-state'
import { UiPaymentInput as CardNumber } from '../../ui'
import DocumentTypeInput from '../../inputs/DocumentTypeInput'
import DocumentInput from '../../inputs/DocumentInput'
import useStore from 'stores/useStore'
import CellPhoneKind from 'pages/ParticipantsPage/CustomFieldsModule/kinds/CellPhoneKind'
import { countryList } from '../../utils'

function CardForm({ instalments = [], input = console.warn }) {
  const { leaderParticipant, isInternationalCreditCard, setIsInternationalCreditCard } = useStore()
  const { value: documentType } = input('document_type') || {}

  const SelectCountry = ({ options = [], allowEmpty = false, multiple = false, ...props }) => (
    <UiTextField select SelectProps={{ native: true, multiple }} {...props}>
      {allowEmpty && <option value={''} />}

      {options.map(([key, label, disabled]) => (
        <option key={key} value={label} disabled={disabled}>
          {label}
        </option>
      ))}
    </UiTextField>
  )

  useEffect(() => {
    if (!isInternationalCreditCard) {
      input('country').onChange('')
    }
  }, [isInternationalCreditCard, input])

  return (
    <form>
      <UiCheckbox
        onChange={() => setIsInternationalCreditCard(!isInternationalCreditCard)}
        value={isInternationalCreditCard}
        label={'Meu cartão foi emitido em outro país'}
      />
      {isInternationalCreditCard && (
        <SelectCountry
          allowEmpty
          options={countryList}
          label="País (Obrigatório)"
          margin="dense"
          {...input('country')}
        />
      )}

      <CardNumber data-private {...input('number')} />
      <NameInput {...input('name')} />
      <UiHbox>
        <ExpirationInput {...input('expiration')} />
        <CvvInput data-private {...input('cvv')} />
      </UiHbox>

      <InstalmentInput options={instalments} {...input('instalments')} />
      {!isInternationalCreditCard && !['cpf', 'cnpj'].includes(leaderParticipant.document_type) && (
        <UiHbox itemProps={undefined}>
          <DocumentTypeInput required types={['cpf', 'cnpj']} {...input('document_type')} />
          <DocumentInput required documentType={documentType} {...input('document_value')} />
        </UiHbox>
      )}
      <CellPhoneKind label="Telefone" required {...input('phone')} />
    </form>
  )
}

function NameInput(props) {
  const isValidName = () => {
    return /^[a-zA-Z\s]*$/.test(props.value)
  }

  return (
    <UiTextField
      label="Nome impresso no cartão"
      required
      {...props}
      helperText={!isValidName() && 'Nome não pode ter acentos.'}
      autoComplete="no"
      error={!isValidName()}
    />
  )
}

export default view(CardForm)
