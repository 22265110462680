const SECONDARY = '#1BA5A0'

export default {
  palette: {
    primary: {
      main: '#4D2140'
    },
    secondary: {
      main: SECONDARY
    },
    background: {
      default: '#E4E4E4'
    }
  },
  typography: {
    fontSize: 13,
    // remover Warning: Material-UI: you are using the deprecated typography
    // variants that will be removed in the next major release.
    // https://material-ui.com/style/typography/#strategies
    // useNextVariants: true
  },
  overrides: {
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: SECONDARY
        }
      }
    },
    MuiInputBase: {
      root: {
        '&$focused': {
          color: SECONDARY
        }
      }
    },
    MuiInput: {
      underline: {
        '&$focused:after': {
          borderBottomColor: SECONDARY
        }
      }
    }
  }
}
