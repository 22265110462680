// https://mobile.ant.design/components/stepper/
import React from 'react'
import View from './view'

import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { LOG, VERSION, LOG_ID } from '../../env'
import { Pixel } from '../../business'
import useStore from '../../stores/useStore'

export default function StepInput({
  min = 0,
  max = Number.POSITIVE_INFINITY,
  value = 0,
  onChange,
  ...props
}) {
  const { event, isAddedToCartOnce, setIsAddedToCartOnce } = useStore()

  onChange = onChange || console.warn
  value = parseInt(value) || 0
  value = Math.min(Math.max(value, min), max)

  const logRocket = () =>
    LogRocket.init(LOG_ID, { release: VERSION }) && setupLogRocketReact(LogRocket)

  props.downProps = {
    title: min,
    disabled: value <= min,
    onClick() {
      onChange(value - 1)
      if (LOG) logRocket()
    },
  }

  props.upProps = {
    title: max,
    disabled: value >= max,
    onClick() {
      event.integrations.pixel_add_to_cart &&
        !isAddedToCartOnce &&
        Pixel.AddToCart(event.integrations.pixel_id)
      setIsAddedToCartOnce()
      onChange(value + 1)
      if (LOG) logRocket()
    },
  }

  function onChangeValue(e) {
    onChange(parseInt(e.target.value))
  }

  return <View {...props} onChange={onChangeValue} value={value} />
}
